import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const bookingRoots = document.querySelectorAll('.booking');

if(bookingRoots && bookingRoots.length > 0) {
    bookingRoots.forEach((bookingRoot: Element) => {
        const root = ReactDOM.createRoot(
            bookingRoot as HTMLElement
        );
        if (bookingRoot instanceof HTMLElement && bookingRoot.dataset && bookingRoot.dataset.properties) {
            root.render(
                <App bookingRoot={bookingRoot} />
            );
        }
    });
}
