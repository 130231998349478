export const FormatDateTime = (dateTimeString: string, getTime: boolean = false, dateFormat: {} = {weekday: 'short',day: '2-digit', month: '2-digit', year: 'numeric'}, timeFormat: {} = {hour: '2-digit', minute: '2-digit'}) => {
    const localeStringOptions: {} = dateFormat;
    const localeTimeStringOptions: {} = timeFormat;

    const locale = 'de-DE';

    if (getTime) {
        return new Date(dateTimeString).toLocaleString(locale, localeTimeStringOptions);
    }
    return new Date(dateTimeString).toLocaleString(locale, localeStringOptions);
}

const padToTwoDigits = (num: number) => {
    return num.toString().padStart(2, '0');
}

export const formatDateTimeWithTimezone = (date: Date) => {
    let year = date.getFullYear();
    let month = padToTwoDigits(date.getMonth() + 1);
    let day = padToTwoDigits(date.getDate());
    let hours = padToTwoDigits(date.getHours());
    let minutes = padToTwoDigits(date.getMinutes());
    let seconds = padToTwoDigits(date.getSeconds());

    let offset = date.getTimezoneOffset();
    let offsetHours = padToTwoDigits(Math.floor(Math.abs(offset) / 60));
    let offsetMinutes = padToTwoDigits(Math.abs(offset) % 60);
    let timezoneSign = offset > 0 ? '-' : '+';

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneSign}${offsetHours}:${offsetMinutes}`;
}
