import {Loader2} from "lucide-react";

export default function Loading(props: any) {

    const {className} = props;

    return (
        <div>
            <Loader2 className={`transition-all text-foreground animate-spin${className ? ` ${className}` : ' w-[40px] h-[40px]'}`}/>
        </div>
    );
}
