import { Button } from "../components/ui/button"

export default function Step(props: any) {

    const {title, description, buttonLabel, handleSetStep, children, setStep, step} = props;

    const handleClick = () => {
        handleSetStep();
    }

    return (
        <div className="w-full flex flex-col">
            <div className="mb-5">
                <div className="text-xl">{title}</div>
                <div>{description}</div>
            </div>
            {children &&
                <div>
                {children}
                </div>
            }
            <div className="flex flex-row gap-5">
                {buttonLabel &&
                    <div className="w-full">
                        <Button className="mt-5 w-full" onClick={() => handleClick()} >{buttonLabel}</Button>
                    </div>
                }
                {setStep &&
                    <div className="w-full">
                        <Button variant="outline" className="mt-5 w-full" onClick={() => setStep(step - 1)} >Zurück</Button>
                    </div>
                }
            </div>
        </div>
    );
}
