import {Fragment, useEffect, useState, useCallback} from "react";
import {FormatDateTime} from "../lib/helper/formatDateTime";
import { Button } from "./ui/button"
import {ArrowLeft, ArrowRight} from "lucide-react";

export default function Month(props: any) {

    const {currentYear, setCurrentYear, currentMonth, setCurrentMonth, availableSlots, selectedDate, allDay, setSelectedDate, selectedDates, setSelectedDates, allowMultiple, selectedSlots, setSelectedSlots} = props;
    const [totalAvailableSlots, setTotalAvailableSlots] = useState<any>(null);

    const determineStart = useCallback(() => {
        if(availableSlots && availableSlots.length > 0) {
            const firstWeekday = availableSlots[0].weekday
            let emptyElements: any[] = [];
            if(firstWeekday === 0) {
                emptyElements = Array(6).fill({ date: '' });
            } else {
                emptyElements = Array((firstWeekday - 1)).fill({ date: '' });
            }
            if(emptyElements) {
                const newAvailableSlots = emptyElements.concat(availableSlots)
                setTotalAvailableSlots(newAvailableSlots);
            }
        }
    }, [availableSlots]);

    const handleClick = (direction: number) => {
        if(direction === 0) {
            const newMonth = (currentMonth - 1);
            if(newMonth === 0) {
                setCurrentYear(currentYear - 1)
                setCurrentMonth(12)
            } else {
                setCurrentMonth(newMonth)
            }
        }
        if(direction === 1) {
            const newMonth = (currentMonth + 1);
            if(newMonth === 13) {
                setCurrentYear(currentYear + 1)
                setCurrentMonth(1)
            } else {
                setCurrentMonth(newMonth)
            }
        }
    }

    const isInSelectedDates = (item: any) => {
        if(selectedDates && selectedDates.length > 0) {
            for(let i in selectedDates) {
                const string = JSON.stringify(selectedDates[i]);
                if(string === JSON.stringify(item)) {
                    return true;
                }
            }
        }
        return false;
    }

    const removeIfExist = (item: any) => {
        const currentSelectedSlots = selectedSlots;
        if(currentSelectedSlots && currentSelectedSlots.length > 0) {
            if(selectedDate.date === item.date) {
                const newSelectedSlots = currentSelectedSlots.filter((selectedSlot: any) => selectedSlot.date !== item.date);
                setSelectedSlots(newSelectedSlots)
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        determineStart();
    }, [determineStart]);

    // useEffect(() => {
    //     console.log(selectedDate)
    // }, [selectedDate]);
    //
    // useEffect(() => {
    //     console.log(selectedDates)
    // }, [selectedDates]);

    return (
        <div className="flex flex-col month">
            <div className="grid grid-cols-2 gap-1 items-center mb-5">
                <div>{FormatDateTime(`${currentYear}-${currentMonth}-01 00:00:00`, false, {month: 'long'})} {currentYear}</div>
                <div className="flex flex-row items-center justify-end gap-1">
                    <Button variant="outline" className="rounded-full w-[40px] h-[40px] p-0" onClick={() => handleClick(0)}>
                        <ArrowLeft />
                    </Button>
                    <Button variant="outline" className="rounded-full w-[40px] h-[40px] p-0" onClick={() => handleClick(1)}>
                        <ArrowRight />
                    </Button>
                </div>
            </div>
            <div className="grid grid-cols-7 gap-1">
                <div className="text-center w-full">Mo</div>
                <div className="text-center w-full">Di</div>
                <div className="text-center w-full">Mi</div>
                <div className="text-center w-full">Do</div>
                <div className="text-center w-full">Fr</div>
                <div className="text-center w-full">Sa</div>
                <div className="text-center w-full">So</div>
                {totalAvailableSlots && totalAvailableSlots.length > 0 &&
                    <Fragment>
                        {totalAvailableSlots.map((item: { date: string, weekday: number, availableSlots: any }, index: number) =>
                            <div key={item.date !== '' ? item.date : (currentYear + currentMonth + index)} className="w-full flex flex-row items-center justify-center">
                                <Button variant={(allowMultiple ? (isInSelectedDates(item)) : JSON.stringify(selectedDate) === JSON.stringify(item)) ? 'default' : 'outline'} className={(item.availableSlots && item.availableSlots.length > 0 && item.date !== '' ? ('available ' + ((allowMultiple ? (isInSelectedDates(item)) : JSON.stringify(selectedDate) === JSON.stringify(item)) ? 'border-primary bg-primary text-primary-foreground' : 'border-primary bg-primary-foreground')) : (item.date === '' ? 'pointer-events-none opacity-0' : 'pointer-events-none opacity-40 unavailable ')) + ' rounded-full w-[40px] h-[40px] p-0'} onClick={allowMultiple && allDay ? () => setSelectedDates(item) : (allowMultiple && !allDay ? () => { if(!removeIfExist(item)) { setSelectedDates(item); setSelectedDate(item); } else { setSelectedDates(item); }  } : () => setSelectedDate(item))}>
                                    {item.date !== '' ? FormatDateTime(item.date, false, {day: '2-digit'}) : ''}
                                </Button>
                            </div>
                        )}
                    </Fragment>
                }
            </div>
        </div>
    );
}
