import {Fragment} from "react";
import { Button } from "./ui/button"

export default function Time(props: any) {

    const {selectedDate, selectedSlot, setSelectedSlot, selectedSlots, setSelectedSlots, allDay, allowMultiple} = props;

    const currentSelectedSlots = selectedSlots;

    const isInSelectedSlots = (item: any) => {
        if(currentSelectedSlots && currentSelectedSlots.length > 0) {
            for(let i in currentSelectedSlots) {
                const identifier = currentSelectedSlots[i].identifier;
                if(item.identifier === identifier && selectedDate.date === currentSelectedSlots[i].date) {
                    return true;
                }
            }
        }
        return false;
    }

    const handleSelectedSlots = (item: any) => {
        let newSelectedSlots: any = [];

        if(isInSelectedSlots(item)) {
            newSelectedSlots = currentSelectedSlots.filter((selectedSlot: any) => selectedSlot.identifier !== item.identifier);
        } else {
            item['date'] = selectedDate.date;
            newSelectedSlots = [...currentSelectedSlots, item];
        }
        setSelectedSlots(newSelectedSlots)
    }

    return (
        <Fragment>
            {selectedDate && selectedDate.availableSlots && selectedDate.availableSlots.length > 0 &&
                <div className="grid grid-cols-2 gap-2">
                    {selectedDate.availableSlots.map((item: {identifier: string, startDateTime: string, endDateTime: string}) =>
                        <Button key={item.identifier} variant={(selectedSlot === item || isInSelectedSlots(item)) ? 'default' : 'outline'} onClick={allowMultiple && !allDay ? () => handleSelectedSlots(item) : () => setSelectedSlot(item)}>{item.startDateTime} - {item.endDateTime}</Button>
                    )}
                </div>
            }
        </Fragment>
    );
}
